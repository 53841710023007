export default () => {
	const LANGUAGE = 'br';

	const dictionary = {
		br: {
			// 404
			404: {
				notFound: 'Não achamos nada',
				pageMovedOrNeverExisted: 'Essa página não existe, volte!',
				backToMainPageButton: 'Voltar para a página inicial',
			},
			// SUCCESS

			success: {
				title: 'Valeu pela compra, tamo junto!',
				orderIdText: 'número da ordem:',
				firstDesc: 'Fica ligado você vai receber um e-mail com sua compra em instantes.',
				secondDesc: 'Guarda este número é o número da sua compra.',
				backToMainPageButton: 'Voltar para a página inicial',
			},
			// FAILED
			failed: {
				title: 'Pagamento recusado, tenta de novo!',
				orderIdText: '',
				firstDesc: 'Vixii o pagamento foi cancelado ou recusado, bora tentar de novo.',
				secondDesc: '',
				backToMainPageButton: 'Voltar para a página inicial',
			},
			// PENDING
			pending: {
				title: 'Pagamento pendente!',
				orderIdText: 'número da ordem:',
				firstDesc: 'Vai receber sua compra por e-mail, fique atento! Verifique caixa de spam se necessário, pode estar lá!',
				secondDesc: 'Guarde o número do pedido, se necessário para entrar em contato com a gente se necessário.',

				backToMainPageButton: 'Voltar para a página inicial',
			},
			// MENU
			menu: {
				home: 'home',
				about: 'sobre',
				accounts: 'contas',
				faq: 'faq',
				skins: 'skins',
				contact: 'contato',
				giveawaysText: 'sorteios',
				giveawaysURL: 'https://instagram.com/thedarksmurf',
			},
			// HOME
			home: {
				pageTitle: 'Conta Unranked Lol by TheDark - Compre sua Smurf, Escolha sua skin',
				mainTextHomePage: 'A melhor conta unranked level 30 do Brasil é só aqui, você escolhe sua skin se quiser, estoque atualizado 24h por dia.',
				normalAccountsButtonText: 'Unranked Aleatória lvl 30+',
				skinsAccountsButtonText: 'Unranked escolhe skin lvl 30+',
			},
			// FEATURE
			feature: {
				whyChooseUs: 'porque escolher',
				storeName: 'TheDark Conta Unranked lol?',
				features: {
					1: {
						iconClass: 'fas fa-search-dollar',
						title: 'SUA MELHOR SMURF É AQUI',
						text: 'Smurf Unranked Level 30, aqui na conta unranked lol, nós sempre permitimos que nossos clientes escolhessem sua smurf, quando iniciamos nossas atividas no início de 2020, não existia ninguém que deixava seu cliente escolhesse sua smurf, fomos pioneiros desde sempre, depois nos copiaram, e agora inovamos mais uma vez e estamos na frente, o único site do brasil que permite que você escolha dentre mais de 1k de contas a sua smurf preferida 100% automático, oferecemos a melhor smurf do mercado pra você, afinal, nós somos APENAS O MELHOR do mercado. Acredite você encontrou o lugar certo.',
					},
					2: {
						iconClass: 'fas fa-check',
						title: 'BAIXA TAXA DE BANIMENTO',
						text: 'Nossas contas são únicas, são criadas em um método 100% privado, o que garante a qualidade de nossas smurfs unranked level 30, garantia de uma taxa de banimento EXTREMAMENTE baixa. Isso pra garantir a satisfação dos nossos clientes, garantindo nossa qualidade.',
					},
					3: {
						iconClass: 'fas fa-envelope-square',
						title: 'ACESSO COMPLETO A CONTA',
						text: 'Você, quer ter o acesso completo a sua conta, ao comprar uma conta unranked level 30 com a gente, ela será 100% sua. Só você terá acesso a sua conta unranked, você nunca vai precisar se preocupar com o "dono original" tentando recuperar a conta, como acontece em outros sites.',
					},
					4: {
						iconClass: 'fas fa-shipping-fast',
						title: 'ENTREGA IMEDIATA',
						text: 'Ta esperando o que para comprar sua smurf? Não perca seu tempo! Depois de fazer sua compra, todos os detalhes da sua conta serão automaticamente enviados por e-mail para você, estamos a disposição para o que você precisar.',
					},
					5: {
						iconClass: 'fas fa-heart',
						title: 'GARANTIA DA CONTA',
						text: 'Nós damos uma garantia de 30 dias, caso você tenha um problema com sua conta, você entra em contato, e lhe daremos outra conta level 30 unranked e renovaremos sua garantia! Vale lembrar que a garantia não cobre mal uso, como: Mau comportamento, abandono, scripts, skin changer, trava lobby, hack, feed intencional etc.',
					},
					6: {
						iconClass: 'fas fa-balance-scale-right',
						title: 'QUALIDADE ACIMA DE QUANTIDADE',
						text: 'Temos orgulho da qualidade de nossas contas unranked. Todas as contas têm jogos de qualidade e vêm com o maior número possível de Essências Azuis e Laranjas para ativar sua skin escolhida, skins ultimate e prestígio são gratuitas para ativar. Nós temos skins de TODOS os campeões atualmente, as skins e contas unranked são colocadas diariamente autoamticamente a todo instante.',
					},
				},
			},
			// FEATURE
			price: {
				countryMpagoScript: 'br',
				title: 'Contas',
				chooseYourRegion: 'Escolha a região',
				regions: {
					las: 'LAS',
					br: 'Brasil',
				},
				writeValidEmail: 'Coloque seu e-mail válido',
				generatePayment: 'Gerar pagamento',
				continue: 'Continuar',
				noStock: 'Sem estoque',
				errorTryAgain: 'Erro, Tente voltar',
				errorNoStock: 'ERRO: Sem estoque disponível.',
				errorPleaseTryAgain: 'ERRO: Por favor, tente novamente.',
				errorCouponInvalidForQuantity: 'ERRO: Cupom inválido para a quantidade do produto.',
				errorCouponExpired: 'ERRO: Cupom Expirado.',
				errorCouponDontExist: 'ERRO: Cupom não existe para o produto selecionado.',
				localCurrency: 'Reais BRASIL',
				localCurrencySymbol: 'R$',
				chooseButton: 'Escolher',
				accountsTitle: 'Contas',
				makeYourPayment: 'Realizar Pagamento',
				price: 'Preço',
				quantity: 'Quantidade',
				coupon: 'Cupom',

				use: 'Usar',
				useCouponToGetDiscount: 'Usar cupom para ter desconto',
				yourEmail: 'Seu e-mail',
			},
			faq: {
				title: 'faq',
				1: {
					iconClass: 'icon fas fa-shield-alt',
					title: 'COMO SABER SE É SEGURO COMPRAR NO CONTA UNRANKED LOL?',
					text: 'Temos orgulho da segurança e satisfação dos nossos clientes. Todas as compras são protegidas pelos métodos de pagamento escolhido, o que significa que suas informações de pagamento são 100% seguras e nosso site é protegido por SSL para segurança adicional. Temos uma GARANTIA de 30 dias em todas as nossas contas, portanto, nada vai acontecer, você está protegido!',
				},
				2: {
					iconClass: 'icon far fa-credit-card',
					title: 'QUAIS MÉTODOS DE PAGAMENTO?',
					text: 'Atualmente aceitamos PIX / MercadoPago e cartões de crédito / débito.',
					//  Também criptomoedas: Bitcoin, Ethereum, Litecoin, Cardano, entre outras.',
				},
				3: {
					iconClass: 'icon far fa-clock',
					title: 'QUANTO TEMPO LEVA PARA MINHA CONTA UNRANKED SER ENTREGUE?',
					text: 'O processo de entrega é IMEDIATO, assim que efetuar o pagamento será enviado um e-mail com os dados da sua conta unranked escolhida. Se você não ver o e-mail, verifique sua pasta de spam ou lixo eletrônico.',
				},
				4: {
					iconClass: 'icon fas fa-hand-holding-usd',
					title: 'COMO PODEMOS VENDER A PREÇOS TÃO BAIXOS?',
					text: 'Mantemos nossos preços baixos garantindo a satisfação dos nossos clientes. Queremos construir relacionamentos futuros com todos nossos clientes.',
				},
				5: {
					iconClass: 'icon fas fa-heartbeat',
					title: 'QUAL É A SUA GARANTIA?',
					text: 'Nossa garantia é de 30 dias é muito simples: se qualquer conta unranked adquirida através de nós for suspensa por qualquer defeito ou falha nossa, você receberá uma conta de compensação gratuita e sua garantia é renovada.',
				},
				6: {
					iconClass: 'icon fas fa-ticket-alt',
					title: 'VOCÊS TEM CUPOM? COMO CONSIGO UM?',
					text: 'Sim, nós temos cupom, siga nossas redes sociais e nosso discord, de vez em quando colocamos alguns cupoms para trabalhar e você pode usá-lo como quiser! Ao escolher um produto no momento de fazer o pagamento, é só inserir o cupom no local indicado e clicar em Utilizar, o desconto será aplicado automaticamente..',
				},
				7: {
					iconClass: 'icon fas fa-medal',
					title: 'VOCÊS FAZEM SORTEIOS?',
					text: 'Sim, de vez em quando faremos sorteios de contas unranked e você pega ela direto no nosso site, escolhe a conta que você quiser dentro das regras do sorteio.',
				},
			},
			footer: {
				contactUs: 'Contato',
				instagramId: 'thedarksmurf',
				discordText: 'Nosso discord',
				discordLink: 'https://discord.gg/3BH6XRd',
				email: 'suporte@contaunrankedlol.com',
				disclaimer: 'Conta Unranked Lol do TheDark NÃO é afiliado, patrocinado, endossado ou associado a League of Legends ou Riot Games, Inc. Todos os direitos autorais, marcas registradas, imagens e marcas de serviço pertencem aos seus respectivos proprietários.',
				disclaimer2: 'Aqui você escolhe qual fragmento de skin você quer que acompanhe sua smurf unranked level 30 +, nós temos as melhores smurfs do mercado, compre agora sua smurf.',
			},
			skins: {
				pageTitle: 'Conta Unranked Lol by TheDark - Escolha sua skin, smurf unranked level 30',
				countryMpagoScript: 'br',
				chooseYourRegion: 'Escolha sua região',
				chooseYourSkin: 'Escolha sua Skin',
				regions: {
					las: 'LAS',
					br: 'Brasil',
				},
				selectAChampion: 'Escolha um campeão',
				placeholderChampInput: 'nome do campeão',
				errorNoStock: 'ERRO: Sem estoque disponível.',
				errorPleaseTryAgain: 'ERRO: Por favor, tente novamente.',
				errorCouponInvalidForQuantity: 'ERRO: cupom inválido para quantidade do produto.',
				errorCouponExpired: 'ERRO: o cupom expirou.',
				errorCouponDontExist: 'ERRO: o cupom não existe para o produto selecionado.',
				localCurrency: 'Real Brasil',
				localCurrencySymbol: 'R$',
				coupon: 'Cupom',
				generatePayment: 'Gerar pagamento',
				select: 'Selecionar',
				noStock: 'Fora de estoque',
				makeThePayment: 'Realizar pagamento',
				price: 'Preço',
				use: 'Usar',
				yourEmail: 'Seu e-mail',
				useCouponToGetDiscount: 'Use um cupom para ter um desconto',
				writeValidEmail: 'Escreva seu e-mail',
			},
		},
	};
	return dictionary[LANGUAGE];
};
