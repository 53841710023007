import React, { useEffect, useState } from 'react';
import dict from '../utils/dictionary';

const Feature = () => {
	const dictionary = dict()['feature'];

	return (
		<>
			<section id='feature' className='feature' style={{ padding: '150px 0px 150px 0px' }}>
				<div className='feature-decor d-none d-xl-block'>
					<div className='feature-circle1'>
						<img style={{ width: '256px', height: '256px' }} src={`${process.env.PUBLIC_URL}/assets/images/fiora-icon.webp`} alt='fiora-icon' />
					</div>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='feature-phone'>
							<img src={`${process.env.PUBLIC_URL}/assets/images/kogmaw-icon.webp`} className='img-fluid' alt='' />
						</div>
						<div className='offset-lg-4 col-lg-8'>
							<div className='row'>
								<div className='col-sm-12 mrgn-md-top'>
									<h2 className='title'>
										{dictionary['whyChooseUs']}
										<span> {dictionary['storeName']}</span>
									</h2>
								</div>
								<div className='col-12 col-md-6'>
									<ul className='feature-style'>
										<li>
											<div className='feature-icon'>
												<i className={dictionary['features'][1]['iconClass']}></i>
											</div>
											<div className='feature-subtitle'>
												<h3>{dictionary['features'][1]['title']}</h3>
											</div>
											<div>
												<p>{dictionary['features'][1]['text']}</p>
											</div>
										</li>
										<li>
											<div className='feature-icon'>
												<i className={dictionary['features'][2]['iconClass']}></i>
											</div>
											<div className='feature-subtitle'>
												<h3>{dictionary['features'][2]['title']}</h3>
											</div>
											<div>
												<p>{dictionary['features'][2]['text']}</p>
											</div>
										</li>
										<li>
											<div className='feature-icon'>
												<i className={dictionary['features'][3]['iconClass']}></i>
											</div>
											<div className='feature-subtitle'>
												<h3>{dictionary['features'][3]['title']}</h3>
											</div>
											<div>
												<p>{dictionary['features'][3]['text']}</p>
											</div>
										</li>
									</ul>
								</div>
								<div className='col-12 col-md-6 sm-m-top'>
									<ul className='feature-style'>
										<li>
											<div className='feature-icon'>
												<i className={dictionary['features'][4]['iconClass']}></i>
											</div>
											<div className='feature-subtitle'>
												<h3>{dictionary['features'][4]['title']}</h3>
											</div>
											<div>
												<p>{dictionary['features'][4]['text']}</p>
											</div>
										</li>
										<li>
											<div className='feature-icon'>
												<i className={dictionary['features'][5]['iconClass']}></i>
											</div>
											<div className='feature-subtitle'>
												<h3>{dictionary['features'][5]['title']}</h3>
											</div>
											<div>
												<p>{dictionary['features'][5]['text']}</p>
											</div>
										</li>
										<li>
											<div className='feature-icon'>
												<i className={dictionary['features'][6]['iconClass']}></i>
											</div>
											<div className='feature-subtitle'>
												<h3>{dictionary['features'][6]['title']}</h3>
											</div>
											<div>
												<p>{dictionary['features'][6]['text']}</p>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Feature;
