import React, { useState } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-scroll';
import dict from '../utils/dictionary';

const Menu = ({ isOnSkinsPage }) => {
	const dictionary = dict()['menu'];

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	const HandleScroll = () => {
		if (window.scrollY >= 60) {
			document.getElementById('Navbar')?.classList.add('darkHeader');
		} else {
			document.getElementById('Navbar')?.classList.remove('darkHeader');
		}
	};
	window.addEventListener('scroll', HandleScroll);
	return (
		<Navbar id='Navbar' className='navbar navbar-expand-lg navbar-light theme-nav fixed-top'>
			<div id='navbar-main' className='container'>
				<NavbarBrand className='navbar-brand' href={`${process.env.PUBLIC_URL}/`}>
					<img style={{ width: '185px', height: '34px' }} src={`${process.env.PUBLIC_URL}/assets/images/contaunrankedlol-logo.webp`} alt='logo' />
				</NavbarBrand>
				<NavbarToggler className='navbar-toggler' onClick={toggle} />
				<Collapse id='navbarSupportedContent' className='default-nav' isOpen={isOpen} navbar>
					<Nav className='ml-auto' navbar>
						<NavItem>
							{isOnSkinsPage ? (
								<a className='nav-link' href='/'>
									{dictionary['home']}
								</a>
							) : (
								<Link href='/' className='nav-link' activeClass='active' to='back-img' spy={true} smooth={true} offset={-70} duration={500} onClick={toggle}>
									{dictionary['home']}
								</Link>
							)}
						</NavItem>
						<NavItem>
							{isOnSkinsPage ? (
								<a className='nav-link' href='/#feature'>
									{dictionary['about']}
								</a>
							) : (
								<Link href='/#feature' className='nav-link' activeClass='active' to='feature' spy={true} smooth={true} offset={10} duration={500} onClick={toggle}>
									{dictionary['about']}
								</Link>
							)}
						</NavItem>

						<NavItem>
							{isOnSkinsPage ? (
								<a className='nav-link' href='/#price'>
									{dictionary['accounts']}
								</a>
							) : (
								<Link href='/#price' className='nav-link' activeClass='active' to='price' spy={true} smooth={true} offset={-70} duration={500} onClick={toggle}>
									{dictionary['accounts']}
								</Link>
							)}
						</NavItem>

						<NavItem>
							{isOnSkinsPage ? (
								<a className='nav-link' href='/#faq'>
									{dictionary['faq']}
								</a>
							) : (
								<Link href='/#faq' className='nav-link' activeClass='active' to='faq' spy={true} smooth={true} offset={-70} duration={500} onClick={toggle}>
									{dictionary['faq']}
								</Link>
							)}
						</NavItem>
						<NavItem>
							<a className='nav-link' href='/skins'>
								{dictionary['skins']}
							</a>
						</NavItem>
						<NavItem>
							<Link href='/#faq' className='nav-link' activeClass='active' to='contact' spy={true} smooth={true} offset={-70} duration={500} onClick={toggle}>
								{dictionary['contact']}
							</Link>
						</NavItem>

						<NavItem>
							<a href={dictionary['giveawaysURL']} target='__blank' className='nav-link'>
								{dictionary['giveawaysText']}
							</a>
						</NavItem>
					</Nav>
				</Collapse>
			</div>
		</Navbar>
	);
};

export default Menu;
